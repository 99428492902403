<template>
    <div v-loading="loading">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header">
                <div class="card-title">
                     <span class="card-icon">
                        <i class="flaticon2-delivery-package text-primary"></i>
                    </span>
                    <h3 class="card-label"><b class="title-header">権限詳細・編集</b></h3>
                </div>
            </div>
            <div class="card-body" style="height:500px;">
                <el-form ref="ruleForm" :model="listRoleEdit" label-width="30%" @keyup.enter.native="submit" class="demo-ruleForm demo-form-edit">

                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label  class="el-form-item__label text-left">権限名 <span class="error-message">*</span></label>
                            <el-form-item prop="role_name" class="item-title-form item-fome-base">
                                <el-input class="bg-gray-item"
                                        placeholder="権限名を入力"
                                        v-model="listRoleEdit.role_name" maxlength="40"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                     <div class="row">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label class="el-form-item__label text-left" >ステータス <span class="error-message">*</span></label>
                            <el-form-item prop="role_status" class="item-title-form item-fome-base">
                                <el-select class="bg-gray-item" v-model="listRoleEdit.role_status" style="width:100%;" placeholder="権限ステータス">
                                    <el-option v-for="status in arrStatus"
                                            :label="status.label"
                                            :value="status.value"
                                            :key="status.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                                <el-checkbox v-model="listRoleEdit.kengen_flg">承認プロセス・権限・アカウント・テナントの変更を許可する</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                                <el-checkbox v-model="listRoleEdit.form_flg">フォーム作成・編集を許可する</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                                <el-checkbox v-model="listRoleEdit.answer_flg">回答の閲覧を許可する</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                                <el-checkbox v-model="listRoleEdit.tennant_flg">テナント検索・設定を許可する</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                                <el-checkbox v-model="listRoleEdit.mail_flg">メール設定を許可とする</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div v-if="currentUser.super_user" class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label class="el-form-item__label" for=""> テナント</label>
                            <el-form-item class="item-title-form item-fome-base" label=""
                                          prop="tenant_code">
                                <el-select class="w-100 bg-gray-item" :disabled="!listRoleEdit.kengen_flg" v-model="listRoleEdit.tenant_code" placeholder="選択してください">
                                    <el-option label="なし" value=" "></el-option>
                                    <el-option
                                        v-for="(item, index) in listTenant"
                                        :key="index"
                                        :label="item.tenant_name"
                                        :value="item.tenant_code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item prop="action" class="button-search" label-width="0px"  style="" >
                        <el-button class="button-edit-role pl-0 pr-0" type="primary" @click.prevent="submit" style="padding-left:4rem; padding-right:4rem;" >入力完了</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <Popup id="confirm" title="確認" :content="title" :title_button="close_popup">
            <b-button class="btn-primary" @click.prevent="check">{{$t("YES")}}</b-button>
        </Popup>
        <Popup id="error_message"  :content="messages_error" :title_button="exit_popup">
        </Popup>
        <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                   :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
            <template slot="header">
                <div class="textwidget text-center">
                    {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
                </div>
            </template>
        </my-dialog>
    </div>
</template>

<style lang="scss" scoped>
.card-custom {
    border-radius: 15px;
}

.card-custom > .card-header {
    padding: 0;
    margin: 0px 21px;
}
</style>

<script>
import apiService from "@/core/services/api.service";
import * as constants from "@/core/config/constant";
import { mapGetters, mapState, mapActions } from "vuex";
import {DETAIL_ROLE,UPDATE_ROLE} from "@/core/services/store/role.module";
import Popup from "@/view/components/Popup";
import {GET_ALL_TENANT} from "@/core/services/store/mtenant.module";
export default {
  components: {Popup},
    data() {
        return {
            roleDetail:[],
            dialogFormVisible: false,
            dialogForm: false,
             ruleForm: {
                 role_name: '',
                 rol_status:'',
                 kengen_flg: false,
                 form_flg: false,
                 answer_flg: false,
                 tennant_flg: false,
                 mail_flg: false,
                 tenant_code: '',
            },
            arrStatus: constants.ROLE_STATUS,
            message:'',
            close_popup: this.$t("NO"),
            exit_popup: this.$t("OK"),
            title: '',
            loading: false,
            messages_error:'',
            not_permisson_popup:false,
            listApproval:[],
        }
    },

    computed: {
        ...mapGetters(['listRoleEdit','currentUser']),
            queryParam() {
            return this.$route.params
        },
    },

    async created() {
        this.listTenant = await this.getAllTenant();
        if (this.hasPermissonCheck('kengen_flg')){
            await this.detailRole(this.queryParam.role_id);
        }else {
            this.not_permisson_popup = true
        }
        this.arrStatus = constants.ROLE_STATUS;
    },

    methods:{
        ...mapActions([DETAIL_ROLE, UPDATE_ROLE,GET_ALL_TENANT]),
        dataDetailAdminUser(data){
            this.roleDetail = data
        },
        submit(){
            this.title = this.$t("CONFIRM_EDIT_ROLE",{role_name:this.listRoleEdit.role_name});
            this.$bvModal.show("confirm");
        },
        check(){
           this.$refs["ruleForm"].validate(async (valid) => {
               this.loading = true;
                this.turnOffErrorMessage("ruleForm");
                this.listRoleEdit.role_name= this.listRoleEdit.role_name.trim();
               if(valid){
                    this.updateRole({
                        role_id: this.listRoleEdit.role_id,
                        role_name: this.listRoleEdit.role_name,
                        role_status: this.listRoleEdit.role_status,
                        kengen_flg: this.listRoleEdit.kengen_flg,
                        form_flg: this.listRoleEdit.form_flg,
                        answer_flg: this.listRoleEdit.answer_flg,
                        tennant_flg: this.listRoleEdit.tennant_flg,
                        mail_flg: this.listRoleEdit.mail_flg,
                        tenant_code: this.listRoleEdit.tenant_code,
                    })
                    .then((response) => {
                        if(response && response.data && response.data.data){
                            if (response.data.code === constants.STATUS_CODE.SUCCESS){
                            this.notifySuccess(this.$t("UPDATE_SUCCESS"));
                                let self = this;
                                setTimeout(function () {
                                    self.$router.push({
                                    name: "list-role",
                                    });
                                }, 500);
                            }else{
                                if (response.data.code === constants.STATUS_CODE.BAD_REQUEST){
                                    if (response.data.data.role_status_approval){
                                        this.messages_error = this.$t("MESSAGE_ERROR_APPROVAL",{data:response.data.data.role_status_approval[0]});
                                        this.$bvModal.show("error_message");
                                    }
                                }
                                this.showErrorMessage(response.data.data, "ruleForm");
                                this.$bvModal.hide("confirm");
                                this.loading = false;
                            }
                    }else{
                        this.notifyError(this.$t("ERROR_SERVER"))
                        this.$bvModal.hide("confirm");
                        this.loading = false;
                    }
                    }).catch((e) => {
                        this.notifyError(e);
                        this.$bvModal.hide("confirm");
                        this.loading = false;
                });
               }
           })
        },

    }
}
</script>

